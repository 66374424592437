<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
    <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
        <g>
            <circle class="st0" cx="125" cy="125" r="124.5"/>
            <g>
                <g>
                    <g>
                        <path id="SVGID_9_" class="st1" d="M117.3,155.2h25.3c0.4,0,0.7,0.3,0.7,0.7c0,3,0.6,14.9,8.2,21.6c0.5,0.4,0.2,1.2-0.5,1.2       h-41.6c-0.6,0-1-0.8-0.5-1.2c2.7-2.3,7.7-8.5,7.7-21.6C116.6,155.5,116.9,155.2,117.3,155.2z"/>
                    </g>
                </g>
                <path class="st1" d="M84,115.4H63c-2.3,0-4.2,1.9-4.2,4.2V169c0,2.3,1.9,4.2,4.2,4.2h21c2.3,0,4.2-1.9,4.2-4.2v-49.4     C88.3,117.3,86.4,115.4,84,115.4z M85.3,119.8V169c0,0.8-0.7,1.5-1.5,1.5H63.3c-0.8,0-1.5-0.7-1.5-1.5v-49.2     c0-0.8,0.7-1.5,1.5-1.5h2.2c0.3,0,0.5,0.2,0.6,0.4c0.3,0.9,1.1,1.5,2,1.5h11c1,0,1.8-0.7,2-1.5c0.1-0.2,0.3-0.4,0.6-0.4h2.2     C84.7,118.3,85.3,119,85.3,119.8z"/>
                <g>
                    <path class="st1" d="M181.9,71.3H79.6c-5.1,0-9.2,4.1-9.2,9.2v29.8h6.4V80.5c0-1.6,1.3-2.8,2.8-2.8h102.3c1.6,0,2.8,1.3,2.8,2.8      v60.2c0,1.6-1.3,2.8-2.8,2.8H93.4v6.4h88.5c5.1,0,9.2-4.1,9.2-9.2V80.5C191.2,75.5,187,71.3,181.9,71.3z"/>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'DevicesAndSessions',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st0{fill:#FFEBE9;}
	.st1{fill:#F46C4D;}
</style>